.FavoritesPage {
  width: 100%;
  height: 100vh;
  float: left;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.FavoritesPage .PageHeader {
  border-bottom: 1px solid #dcdcdc;
}
.FavoritesPage .FrequentSearches {
  border-bottom: 1px solid #dcdcdc;
}
.FavoritesPage .RiskMoments {
  border-bottom: 1px solid #dcdcdc;
}
.FavoritesPage .MainContent .info {
  border-bottom: 1px solid #dcdcdc;
  padding: 20px;
}
.FavoritesPage .MainContent .info .content {
  max-width: 400px;
}
.FavoritesPage .MainContent .info .content .buttons {
  display: flex;
  margin: 10px 0 0;
}
.FavoritesPage .MainContent .info .content .buttons > button {
  margin: 0 20px 0 0;
}
.FavoritesPage .MainContent .info .content .buttons .import input {
  display: none;
}
.FavoritesPage .MainContent .export {
  border-bottom: 1px solid #dcdcdc;
  padding: 20px;
}
.FavoritesPage .MainContent .cases {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
}
.FavoritesPage .MainContent .cases > div {
  margin: 0 20px 20px 0;
}
