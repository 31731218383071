.SideMenu {
  position: absolute;
  right: 12px;
  top: 0;
  height: 100vh;
  z-index: 100;
  display: flex;
}
.SideMenu .badge {
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  top: 15px;
  right: 15px;
  position: relative;
  cursor: pointer;
}
.SideMenu .badge.closed {
  background-color: #ffffff;
  border: 1px solid #007079;
}
.SideMenu .badge.closed span {
  font-weight: 500;
  font-size: 24px;
  color: #eb0037;
  margin: 2px 0 0 0;
}
.SideMenu .badge.opened {
  background-color: #007079;
  border: 1px solid #007079;
}
.SideMenu .badge.opened svg {
  color: white;
}
.SideMenu .badge:focus-visible {
  outline: 2px dashed var(--eds_interactive__focus, #007079);
  outline-offset: 2px;
}
.SideMenu .SidePanel {
  position: relative;
  overflow-y: auto;
}
.SideMenu .SidePanel .items {
  display: flex;
  flex-direction: column;
}
.SideMenu .SidePanel .items .item {
  padding: 15px 15px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.SideMenu .SidePanel .items .item .icon {
  width: 40px;
  display: flex;
  justify-content: center;
  color: #007079;
  margin: 0 10px 0 0;
}
.SideMenu .SidePanel .items .item .initials {
  width: 40px;
  display: flex;
  justify-content: center;
  color: #eb0037;
  margin: 0 5px 0 0;
  font-weight: 500;
  font-size: 24px;
}
.SideMenu .SidePanel .items .item:hover,
.SideMenu .SidePanel .items .item:focus-visible {
  background: var(--equiGreen2);
  outline: none;
}
.SideMenu .SidePanel .SelectField {
  padding: 20px 25px;
}
