.PreviewCaseFlat {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  position: relative;
  cursor: pointer;
  padding: 15px;
}
.PreviewCaseFlat .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px 0;
}
.PreviewCaseFlat .head .left {
  display: flex;
}
.PreviewCaseFlat .head .left h4 {
  font-weight: 500;
  font-size: 17px;
}
.PreviewCaseFlat .head .left .SeverityBadge {
  margin: 0 10px 0 0;
  flex-shrink: 0;
}
.PreviewCaseFlat .head .right p {
  font-size: 12px;
  line-height: 24px;
}
.PreviewCaseFlat .body {
  display: flex;
}
.PreviewCaseFlat .body p {
  font-size: 13px;
  line-height: 24px;
}
.PreviewCaseFlat:hover,
.PreviewCaseFlat:focus {
  outline: none;
  background-color: var(--equiGreen2);
}
.PreviewCaseFlat.selected {
  background-color: #007079;
}
.PreviewCaseFlat.selected .head h4,
.PreviewCaseFlat.selected .body h4,
.PreviewCaseFlat.selected .head p,
.PreviewCaseFlat.selected .body p {
  color: white;
}
.PreviewCaseFlat.selected .SeverityBadge {
  background-color: white;
}
.PreviewCaseFlat.selected .SeverityBadge span {
  color: #007079;
}
.PreviewCaseFlat.selected:hover,
.PreviewCaseFlat.selected:focus {
  background-color: #004F55;
}
.PreviewCaseFlat.selected:hover .SeverityBadge span,
.PreviewCaseFlat.selected:focus .SeverityBadge span {
  color: #004F55;
}
