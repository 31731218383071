.Auth {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 50px 0 0 0;
}
.Auth span {
  margin: 25px 0 0 0;
}
