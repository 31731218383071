.PreviewCase {
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 285px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  position: relative;
  cursor: pointer;
  justify-content: space-between;
  margin: 0 20px 20px 0;
}
.PreviewCase .main {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  margin: 0 0 20px 0;
  overflow: hidden;
}
.PreviewCase .main > h4 {
  font-weight: 500;
  font-size: 17px;
  padding: 0 15px 0 0;
}
.PreviewCase .main > p {
  margin: 8px 0 0 0;
  font-size: 13px;
  line-height: 24px;
}
.PreviewCase .footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
  background-color: #f5f5f5;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.PreviewCase .footer > p {
  font-size: 12px;
  line-height: 24px;
}
.PreviewCase .severity {
  position: absolute;
  right: 10px;
  top: 10px;
}
.PreviewCase:hover,
.PreviewCase:focus {
  outline: none;
  background-color: var(--equiGreen2);
}
.PreviewCase:hover .footer,
.PreviewCase:focus .footer {
  background-color: var(--equiGreen2);
}
.PreviewCase.selected {
  background-color: #007079;
}
.PreviewCase.selected .main > h4,
.PreviewCase.selected .main > p {
  color: white;
}
.PreviewCase.selected .footer {
  background-color: #007079;
}
.PreviewCase.selected .footer > p {
  color: white;
}
.PreviewCase.selected .SeverityBadge {
  background-color: white;
}
.PreviewCase.selected .SeverityBadge span {
  color: #007079;
}
.PreviewCase.selected:hover,
.PreviewCase.selected:focus {
  background-color: #004F55;
}
.PreviewCase.selected:hover .footer,
.PreviewCase.selected:focus .footer {
  background-color: #004F55;
}
.PreviewCase.selected:hover .SeverityBadge span,
.PreviewCase.selected:focus .SeverityBadge span {
  color: #004F55;
}
