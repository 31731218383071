.FilterBox {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
}
.FilterBox .head {
  height: 50px;
  background-color: #eaf4f9;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  flex-shrink: 0;
}
.FilterBox .head span {
  font-weight: 500;
}
.FilterBox .head svg {
  color: #9ca6ac;
}
.FilterBox .content {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
}
