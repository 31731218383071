.PageHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 15px 20px 15px;
  box-sizing: border-box;
}
.PageHeader .logo {
  display: flex;
  margin: 0 0 10px 0;
  cursor: pointer;
}
.PageHeader .logo span {
  font-weight: 500;
  font-size: 36px;
  line-height: 36px;
  color: #eb0037;
}
.PageHeader .logo svg {
  margin: -20px 0 0 5px;
}
.PageHeader .searcharea {
  display: flex;
}
.PageHeader .searcharea .search {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  border: 1px solid #007079;
  margin: 20px 0px 15px 0;
}
.PageHeader .buttons {
  display: flex;
  justify-content: space-between;
  margin: 5px 0 0 0;
  align-items: center;
}
.PageHeader .tips {
  margin: 15px 0 0 0;
}
.PageHeader .tips p {
  width: 100%;
}
.PageHeader .tips p b {
  font-size: 20px;
}
