.FrequentSearches {
  padding: 20px 20px 15px 20px;
}
.FrequentSearches .title {
  margin: 0 0 10px 0;
}
.FrequentSearches .icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.FrequentSearches .icons > button {
  margin: 5px 5px;
  cursor: pointer;
}
.FrequentSearches .icons > button > p {
  margin: 10px 0 0 0;
}
