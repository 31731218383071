.CloudWrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dcdcdc;
  padding: 20px;
}
.CloudWrapper .button {
  width: fit-content;
}
.CloudWrapper .SafexWordCloud {
  margin: 20px 0 0px 0;
}
