.FAQPage {
  width: 100%;
  height: 100vh;
  float: left;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.FAQPage .PageHeader {
  border-bottom: 1px solid #dcdcdc;
}
.FAQPage .FrequentSearches {
  border-bottom: 1px solid #dcdcdc;
}
.FAQPage .RiskMoments {
  border-bottom: 1px solid #dcdcdc;
}
.FAQPage .questions {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
}
.FAQPage .questions .answer:not(:nth-last-child(1)) {
  margin: 0 0 10px 0;
}
