.AdvancedQueryInfo {
  display: flex;
  justify-content: flex-end;
}
.AdvancedQueryInfo .advanced {
  color: #007079;
  display: flex;
  align-items: center;
  padding-right: 2px;
  cursor: pointer;
}
.AdvancedQueryInfo .advanced:focus-visible {
  outline: 2px dashed var(--eds_interactive__focus, #007079);
  outline-offset: 2px;
  border-radius: 3px;
}
.AdvancedQueryInfo .advanced .advancedtext {
  display: flex;
  padding-left: 5px;
}
.AdvancedQueryInfo .tips {
  padding: 25px 0 0 0;
}
