@import url('https://eds-static.equinor.com/font/equinor-font.css');

#root,
body,
html {
    overflow: unset !important;
    margin: 0;
}

body {
    font-family: 'Equinor', sans-serif;
}

.MuiTypography-root {
    font-family: 'Equinor', sans-serif !important;
}

.MuiPickersToolbar-toolbar {
    background-color: #007079 !important;
}

.tippy-box[data-animation=fade][data-state=hidden] {
    opacity: 0
}

[data-tippy-root] {
    max-width: calc(100vw - 10px)
}

.tippy-box {
    position: relative;
    background-color: #007079;
    color: #fff;
    border-radius: 14px;
    font-size: 14px;
    line-height: 1.4;
    outline: 0;
    transition-property: transform, visibility, opacity
}

.tippy-box[data-placement^=top] > .tippy-arrow {
    bottom: 0
}

.tippy-box[data-placement^=top] > .tippy-arrow:before {
    bottom: -7px;
    left: 0;
    border-width: 8px 8px 0;
    border-top-color: initial;
    transform-origin: center top
}

.tippy-box[data-placement^=bottom] > .tippy-arrow {
    top: 0
}

.tippy-box[data-placement^=bottom] > .tippy-arrow:before {
    top: -7px;
    left: 0;
    border-width: 0 8px 8px;
    border-bottom-color: initial;
    transform-origin: center bottom
}

.tippy-box[data-placement^=left] > .tippy-arrow {
    right: 0
}

.tippy-box[data-placement^=left] > .tippy-arrow:before {
    border-width: 8px 0 8px 8px;
    border-left-color: initial;
    right: -7px;
    transform-origin: center;
}

.tippy-box[data-placement^=right] > .tippy-arrow {
    left: 0
}

.tippy-box[data-placement^=right] > .tippy-arrow:before {
    left: -7px;
    border-width: 8px 8px 8px 0;
    border-right-color: initial;
    transform-origin: center;
}

.tippy-box[data-inertia][data-state=visible] {
    transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11)
}

.tippy-arrow {
    width: 16px;
    height: 16px;
    color: #007079
}

.tippy-arrow:before {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid
}

.tippy-content {
    position: relative;
    padding: 5px 9px;
    z-index: 1
}

.tippy-box[data-animation=scale][data-placement^=top] {
    transform-origin: bottom
}

.tippy-box[data-animation=scale][data-placement^=bottom] {
    transform-origin: top
}

.tippy-box[data-animation=scale][data-placement^=left] {
    transform-origin: right
}

.tippy-box[data-animation=scale][data-placement^=right] {
    transform-origin: left
}

.tippy-box[data-animation=scale][data-state=hidden] {
    transform: scale(.5);
    opacity: 0
}

