.Cases {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
}
.Cases .buttons {
  margin: 0 0 20px 0px;
}
.Cases .content {
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  width: 100%;
}
