.MainContent {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 0 380px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: 10px;
  scrollbar-color: #c7cbd1 white;
  background-color: #f3f3f3;
}
.MainContent::-webkit-scrollbar,
.MainContent::-webkit-scrollbar {
  width: 12px;
}
.MainContent::-webkit-scrollbar-track {
  background-color: white;
}
.MainContent::-webkit-scrollbar-thumb {
  background-color: #c7cbd1;
  border-radius: 0px;
}
