.Filters {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.Filters > div {
  margin: 0 0px 20px 0;
}
@media only screen and (min-width: 1260px) {
  .Filters {
    flex-direction: row;
  }
  .Filters > div {
    margin: 0 20px 0 0;
  }
}
