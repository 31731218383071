.FilterWrapper {
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  border-bottom: 1px solid #dcdcdc;
  padding: 20px;
}
.FilterWrapper .button {
  width: fit-content;
  margin-right: 15px;
}
.FilterWrapper .filters {
  margin: 20px 0 0 0;
}
