.LandingPage {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
.LandingPage .core {
  width: 380px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25));
  background-color: white;
  border-radius: 15px;
  padding: 20px 0 0 0;
  height: fit-content;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.LandingPage .core .PageHeader {
  border-bottom: 1px solid #dcdcdc;
  padding: 20px 25px;
}
.LandingPage .core .FrequentSearches {
  border-bottom: 1px solid #dcdcdc;
  padding: 20px 25px;
}
.LandingPage .core .RiskMoments {
  padding: 25px 25px 5px 25px;
}
.LandingPage .core .dots {
  text-align: center;
  margin: 75px 0 85px 0;
}
.LandingPage .core::-webkit-scrollbar,
.LandingPage .core::-webkit-scrollbar {
  width: 12px;
}
.LandingPage .core::-webkit-scrollbar-track {
  background-color: white;
}
.LandingPage .core::-webkit-scrollbar-thumb {
  background-color: #c7cbd1;
  border-radius: 0px;
}
.LandingPage .search {
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25));
  background-color: white;
  border-radius: 25px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;
  bottom: 15px;
  padding: 10px 15px;
  cursor: pointer;
  z-index: 1000;
}
.LandingPage .search:hover {
  background-color: #c3f3d2;
}
.LandingPage .search svg {
  margin: 0 10px 0 0;
  color: #007079;
}
