.SidePanel {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: white;
  position: absolute;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  top: 0;
  scrollbar-width: 10px;
  scrollbar-color: #c7cbd1 white;
  z-index: 1;
}
.SidePanel.FULL {
  width: 380px;
}
.SidePanel.SMALL {
  width: 250px;
}
.SidePanel.LEFT {
  left: 0;
}
.SidePanel.RIGHT {
  right: 0;
}
.SidePanel::-webkit-scrollbar,
.SidePanel::-webkit-scrollbar {
  width: 12px;
}
.SidePanel::-webkit-scrollbar-track {
  background-color: white;
}
.SidePanel::-webkit-scrollbar-thumb {
  background-color: #c7cbd1;
  border-radius: 0px;
}
