.HomePage {
  width: 100%;
  height: 100vh;
  float: left;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.HomePage .PageHeader {
  border-bottom: 1px solid #dcdcdc;
}
.HomePage .FrequentSearches {
  border-bottom: 1px solid #dcdcdc;
}
.HomePage .RiskMoments {
  border-bottom: 1px solid #dcdcdc;
}
.HomePage .cases {
  padding: 20px;
}
