.MetaInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 15px 25px 15px;
  box-sizing: border-box;
  align-items: center;
}
.MetaInfo .content {
  display: flex;
  justify-content: center;
}
.MetaInfo .content > p {
  padding-top: 7px;
}
