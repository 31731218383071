.DesignPage {
  margin: 0 0 200px 0;
}
.DesignPage .category {
  display: flex;
  flex-direction: column;
  background-color: #e4f8ff;
  padding: 0 0 30px 0;
  margin: 15px 0 15px 15px;
  float: left;
}
.DesignPage .category > * {
  margin: 30px 30px 0 30px;
  position: relative;
}
.DesignPage .category .variant {
  display: flex;
}
.DesignPage .category .variant > * {
  margin: 0 30px 0 0;
}
.DesignPage .category .dark {
  background: #466b79;
  padding: 15px;
}
.DesignPage .category .tag {
  position: absolute;
  padding: 7px 10px;
  background-color: #466b79;
  color: white;
  z-index: 2;
  border-radius: 10px;
  font-size: 14px;
  right: -16px;
  top: -16px;
}
