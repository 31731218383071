a,
a:hover {
  text-decoration: none;
  color: unset;
}

.highlightedText {
  background: yellow;
  color: black;
  padding: 0;
}