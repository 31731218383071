.SeverityBadge {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SeverityBadge span {
  color: white;
  font-size: 14px;
  font-weight: bold;
}
.SeverityBadge.A {
  background-color: #eb0037;
}
.SeverityBadge.B {
  background-color: #b30d2f;
}
.SeverityBadge.C {
  background-color: #ecbf4d;
}
.SeverityBadge.D {
  background-color: #449a40;
}
.SeverityBadge.E {
  background-color: #4bb748;
}
