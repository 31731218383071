.SafexWordCloud {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
}
.SafexWordCloud .head {
  background-color: #eaf4f9;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-direction: column;
}
.SafexWordCloud .head .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.SafexWordCloud .head .chips {
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  padding: 0 12px;
}
.SafexWordCloud .head .chips .chip {
  background-color: white;
}
.SafexWordCloud .head .chips .chip:last-child {
  margin-bottom: 20px;
}
.SafexWordCloud .content {
  padding: 15px;
}
