.RiskMoments {
  padding: 20px;
}
.RiskMoments ul li {
  margin: 0 0 10px 0;
  padding-bottom: 10px;
}
.RiskMoments ul li span {
  cursor: pointer;
}
.RiskMoments ul li span:focus-visible {
  outline: 2px dashed var(--eds_interactive__focus, #007079);
  outline-offset: 2px;
}
.RiskMoments .extra {
  font-size: 12px;
}
