.FullCase {
  background-color: white;
}
.FullCase .block {
  padding: 20px;
  border-bottom: 1px solid #dcdcdc;
}
.FullCase .block:nth-child(odd) {
  background-color: #fafafa;
}
.FullCase .block .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px 0;
}
.FullCase .block .head .icon {
  width: 30px;
  height: 30px;
  background-color: #007079;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: white;
}
.FullCase .block .head .icon:hover {
  color: #007079;
  background-color: var(--equiGreen2);
}
.FullCase .block .head .icon:focus {
  outline: 2px dashed var(--eds_interactive__focus, #007079);
  outline-offset: 2px;
}
.FullCase .block .mainTitle {
  margin: 0 0 5px 0;
  font-weight: 500;
}
.FullCase .block .title {
  margin: 0 0 5px 0;
}
.FullCase .block .links {
  display: flex;
  flex-wrap: wrap;
}
.FullCase .block .links > a {
  margin: 10px 15px 0 0;
}
.FullCase .block .actions {
  display: flex;
  justify-content: center;
  margin: 0 8px;
}
.FullCase .block .actions > button {
  cursor: pointer;
}
.FullCase .block1 {
  border-top: 5px solid #007079;
  background-color: #f8feff;
}
